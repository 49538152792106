<template>
    <div class="home">
      <el-menu default-active="" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="">全部</el-menu-item>
        <el-menu-item index="0">未审核</el-menu-item>
        <el-menu-item index="1">审核通过</el-menu-item>
        <el-menu-item index="2">审核未通过</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
<el-form-item label="审核时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="提交时间">
                <el-date-picker
                    v-model="timevalue2"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>

            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input style="width:300px;"  v-model="formInline.query" placeholder="用户账号、审核员、用户编号"></el-input>
            <el-button style="margin-left:20px;" type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div style="text-align: left;margin-left:20px;" @change="onSubmit('CHA')" >
                <el-radio v-model="formInline.sort" label="asc" >升序</el-radio>
                <el-radio v-model="formInline.sort" label="desc" >降序</el-radio>
            </div>
      <div class="pt20"></div>

      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
          <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column
          prop="id" 
          label="审核编号">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户账号">
        </el-table-column>

        <el-table-column
          prop="user.uid"
          label="用户编号">
        </el-table-column>

        <el-table-column
          prop="created_at"
          label="提交时间">
        </el-table-column>

        <el-table-column
          prop="content" 
          width="400"
          label="待审核内容">
        </el-table-column>
        <el-table-column
          prop="status" 
          width="300"
          label="审核结果">
          <template slot-scope="scope">
            {{scope.row.status==1?'审核通过':''}}{{scope.row.status==2?'审核未通过':''}}
            <div v-if="scope.row.status==0">
              <el-radio v-model="scope.row.status2" :label="1">审核通过</el-radio>
              <el-radio v-model="scope.row.status2" :label="2">审核未通过</el-radio>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="operator_name" 
          label="审核人">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1&& scope.row.status==0" @click="goedit(scope.row)" size="mini" type="info" plain>提交</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      edit:location.edit,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
         e_sdate:'',
         e_edate:'',
         t_sdate:'',
         t_edate:'',
         query:'',
         page:1,
         sort:'desc',
         status:'',
      },
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     initdata:{
         e_sdate:'',
         e_edate:'',
         t_sdate:'',
         t_edate:'',
         query:'',
         page:1,
         sort:'desc',
         status:'',
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  watch:{
    'formInline.sort':{
      handler(){
        this.onSubmit('CHA')
      }
    }
  },
  methods: {
    handleSelect(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      onSubmit(CHA){
        let _this = this;
         _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.e_sdate = '';
          _this.formInline.e_edate = '';
          _this.formInline.t_sdate = '';
          _this.formInline.t_edate = '';
          if(_this.timevalue){
            _this.formInline.e_sdate	 = formatDate(_this.timevalue[0]);
            _this.formInline.e_edate = formatDate(_this.timevalue[1]);
          }
          if(_this.timevalue2){
            _this.formInline.t_sdate	 = formatDate(_this.timevalue2[0]);
            _this.formInline.t_edate = formatDate(_this.timevalue2[1]);
          }
        axios.get(config.descritionExamine,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
           _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      goedit(obj){
        let _this = this;
          if(!obj.status2){
              _this.$message.error('请选择审核结果！');
            return
          }
        let postdata = {
          id:obj.id,
          status:obj.status2,
          updated_at:obj.updated_at
        }
        axios.post(config.descritionExamine,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.onSubmit();
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.org_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.descritionExamine,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
</style>